export const Work = [
  {
    id: 1,
    name: 'Angie',
    poste: 'Data Engineer',
    contrat: 'Internship & Freelance',
    location: 'Paris, France',
    date: '07/2019 - 02/2020',
    tags: ['Python', 'Flask', 'Excel', 'Pandas', 'BeautifulSoup'],
  },
  {
    id: 2,
    name: 'Angie',
    poste: 'Data Engineer',
    contrat: 'Part-time internship',
    location: 'Paris, France',
    date: '11/2020 - 03/2021',
    tags: ['Python', 'Excel', 'Pandas', 'BeautifulSoup'],
  },
  {
    id: 3,
    name: 'Lyodge',
    poste: 'Fullstack Developer',
    contrat: 'Freelance',
    location: 'Paris, France',
    date: '04/2021 - 01/2022',
    tags: ['ReactJS', 'NodeJS', 'MongoDB', 'Figma', 'Express', 'CSS', 'Docker'],
  },
  {
    id: 4,
    name: 'Air Liquide',
    poste: 'Data Scientist',
    contrat: 'Internship',
    location: 'Bagneux, France',
    date: '03/2023 - 09/2023',
    tags: ['Python', 'BeautifulSoup', 'Selenium', 'Pandas'],
  },
];
