import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import { darkTheme, lightTheme } from './utils/Theme';
import { useEffect, useState } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import Main from './pages/Main';
import { AnimatePresence } from 'framer-motion';
import Tab from './components/Tab';
import Skills from './pages/Skills';
import Experiences from './pages/Experiences';
import Projects from './pages/Projects';

const Background = styled.div`
  background-color: ${(props) => props.theme.background};
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;

  @media screen and (max-width: 1475px) {
    flex-direction: column;
    height: auto;
  }
`;

const MainContainer = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 60%;
  border-radius: 50px;
  padding: 2rem;
  box-shadow: 7px 13px 73px 25px rgba(0, 0, 0, 0.15);
  transition: 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 650px) {
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 0rem;
    border-radius: 0px;
  }
`;

function App() {
  const [theme, setTheme] = useState(null);
  const location = useLocation();

  const handleTheme = () => {
    if (theme === 'dark') setTheme('light');
    else setTheme('dark');

    localStorage.setItem('theme', theme);
  };

  useEffect(() => {
    const storageTheme = localStorage.getItem('theme');
    if (storageTheme) setTheme(storageTheme);
    else setTheme('dark');
  }, []);

  useEffect(() => {
    if (theme) localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <div>
      <GlobalStyle />
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <Background>
          <MainContainer>
            <NavBar theme={theme} handleTheme={handleTheme} />
            <AnimatePresence mode="wait">
              <Routes key={location.pathname} location={location}>
                <Route path="/" element={<Main />} />
                <Route path="/skills" element={<Skills />} />
                <Route
                  path="/experiences"
                  element={<Experiences theme={theme} />}
                />
                <Route path="/projects" element={<Projects theme={theme} />} />
              </Routes>
            </AnimatePresence>
            <Tab location={location.pathname} />
          </MainContainer>
        </Background>
      </ThemeProvider>
    </div>
  );
}

export default App;
