import React from 'react';
import styled from 'styled-components';

import Avatar from '../assets/avatar.png';

import { ReactComponent as Video } from '../assets/svg/video.svg';
import { ReactComponent as Rocket } from '../assets/svg/rocket.svg';
import { ReactComponent as Trophy } from '../assets/svg/trophy.svg';
import AnimatedPage from '../components/AnimatedPage';
import { motion } from 'framer-motion';

const animationLeft = {
  initial: { opacity: 0, x: 100 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
      when: 'beforeChildren',
    },
  },
};

const zoomOut = {
  initial: { opacity: 1, scale: 0 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.7,
      delay: 0.5,
    },
  },
};

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media screen and (max-width: 1475px) {
    flex-direction: column;
    height: auto;
  }
`;

const AboutMe = styled(motion.div)`
  color: ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 2rem;

  h1 {
    font-size: 52px;
  }
  h3 {
    font-weight: 900;
  }
  p {
    font-size: 16.5px;
  }
  .blue {
    color: ${(props) => props.theme.blue};
  }

  .yellow {
    color: ${(props) => props.theme.yellow};
  }
  .bold {
    font-weight: bolder;
    font-size: 19px;
    color: ${(props) => props.theme.secondary};
  }

  @media screen and (max-width: 1475px) {
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 1rem;

    h1 {
      font-size: 42px;
    }
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  .avatar {
    width: 20rem;
  }

  @media screen and (max-width: 1580px) {
    width: 30%;
    .avatar {
      width: 18rem;
    }
  }
  @media screen and (max-width: 1475px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

const Divider = styled(motion.span)`
  border: 1px solid ${(props) => props.theme.text};
  width: 60%;
  margin-top: 1rem;
`;

const SubBox = styled(motion.div)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  p {
    width: 80%;
  }
`;

const Hobbies = styled.div``;

const VideoIconStyled = styled(Video)`
  height: 30px;
  width: 30px;
`;

const TrophyIconStyled = styled(Trophy)`
  height: 30px;
  width: 30px;
`;
const RocketIconStyled = styled(Rocket)`
  height: 30px;
  width: 30px;
`;

const CVButton = styled(motion.a)`
  width: 40%;
  height: 3rem;
  border-radius: 15px;
  cursor: pointer;
  border: transparent;
  transition: 0.4s ease-in-out;

  background-color: ${(props) => props.theme.text};
  margin-top: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.yellow};
  }

  p {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Josefin Sans';
    color: ${(props) => props.theme.body};
  }

  @media screen and (max-width: 1580px) {
    width: 60%;
  }
  @media screen and (max-width: 1475px) {
    margin-bottom: 4rem;
  }
`;

const Main = () => {
  return (
    <AnimatedPage>
      <Container>
        <AboutMe variants={animationLeft} initial="initial" animate="animate">
          <h1>👋🏽Shiron BESKIWIN</h1>
          <h3>
            <span className="yellow">DATA SCIENTIST</span> &{' '}
            <span className="blue">EPITECH STUDENT</span>
          </h3>
          <Divider />
          <br />
          <br />
          <p>
            Hello, my name is Shiron, I'm 22. I'm a{' '}
            <span className="bold">data scientist</span> and I am currently a
            student at <span className="bold">Epitech in the 5th year</span>.
            <br />
            <br />
            During my time at Epitech, I had the opportunity to learn and
            experiment a variety of concepts in computer science through
            projects such as <span className="bold">⚙️algorithms</span>,{' '}
            <span className="bold">💻web & 📲mobile programming</span>,{' '}
            <span className="bold">📟software</span>,{' '}
            <span className="bold">🎮video games</span>, and{' '}
            <span className="bold">🔧devops</span>.
          </p>
          <Divider />
          <br />
          <br />
          <Hobbies>
            <p>I have many hobbies when i'm not coding :</p>
            <br />
            <SubBox>
              <VideoIconStyled />{' '}
              <p>
                I have fun <span className="bold">editing video & photos </span>
                and learn{' '}
                <span className="bold">visual effects, compositing</span> etc.
              </p>
            </SubBox>
            <br />
            <SubBox>
              <RocketIconStyled />
              <p>
                I follow the news about
                <span className="bold"> astronomy</span> and view content
                related to the topic.
              </p>
            </SubBox>
            <br />
            <SubBox>
              <TrophyIconStyled />
              <p>
                I do some <span className="bold">physical activities </span>
                like going to the gym and playing football with friends
              </p>
            </SubBox>
          </Hobbies>
        </AboutMe>
        <AvatarContainer>
          <motion.img
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] }}
            className="avatar"
            src={Avatar}
            alt="logo_sb"
          />
          <CVButton
            href="./Shiron_BESKIWIN_CV.pdf"
            download
            variants={zoomOut}
            whileHover={{ scale: 1.1 }}
          >
            <p>📑 Download CV</p>
          </CVButton>
        </AvatarContainer>
      </Container>
    </AnimatedPage>
  );
};

export default Main;
