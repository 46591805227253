import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Send } from '../assets/svg/send.svg';
import { ReactComponent as Github } from '../assets/svg/github.svg';

const Container = styled(motion.div)`
  padding: 3rem;
  width: 42rem;
  height: 20rem;
  border-radius: 60px;
  z-index: 1;

  background: ${(props) =>
    props.selectedtheme === 'dark' ? 'rgb(50, 50, 50)' : 'rgb(240,240,240)'};
  background: ${(props) =>
    props.selectedtheme === 'dark'
      ? 'linear-gradient(180deg, rgba(50, 50, 50, 0.15) 0%, rgba(40, 40, 40, 1) 100%)'
      : 'linear-gradient(180deg, rgba(240,240,240,0.35) 0%, rgba(242,242,242,1) 100%)'};

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;

  position: relative;

  h2 {
    color: ${(props) => props.theme.bold};
    font-size: 35px;
  }
  h3 {
    color: ${(props) => props.theme.text};
  }
  p {
    color: ${(props) => props.theme.text};
  }

  @media screen and (max-width: 375px) {
    width: 20rem;
    height: 13rem;
  }
`;

const TechContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: 90%;
  span {
    color: ${(props) => props.theme.text};
    font-size: 9px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
`;

const IdContainer = styled.div`
  position: absolute;
  top: -40px;
  left: 650px;
  z-index: -1;

  h4 {
    color: ${(props) => props.theme.text};
    color: transparent;
    -webkit-text-stroke: 0.03rem ${(props) => props.theme.text};
    font-size: 100px;
  }

  @media screen and (max-width: 375px) {
    top: -40px;
    left: 330px;

    h4 {
      font-size: 80px;
    }
  }
`;

const ProjectInfo = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const PicContainer = styled.div`
  height: 19rem;
  width: 14rem;
  border-radius: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 19rem;
    pointer-events: none;
  }

  @media screen and (max-width: 375px) {
    display: none;
  }
`;

const ProjectDetails = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: 375px) {
    width: 75%;
  }
`;

const Divider = styled.span`
  border: 1px solid
    ${(props) =>
      props.color === 'yellow' ? props.theme.yellow : props.theme.blue};
  width: 40%;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const LinkContainer = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
`;

const GithubIconStyled = styled(Github)`
  height: 30px;
  width: 30px;
  fill: ${(props) => props.theme.text};
`;

const SendIconStyled = styled(Send)`
  height: 30px;
  width: 30px;
  fill: ${(props) => props.theme.text};
`;

const ProjectCard = ({ projectData, theme }) => {
  return (
    <Container
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] }}
      selectedtheme={theme}
    >
      <TechContainer>
        {projectData.tags.map((tag, index) => {
          return <span key={index}>{tag}</span>;
        })}
      </TechContainer>
      <ProjectInfo>
        <PicContainer>
          <img alt="pic" src={projectData.pic} />
        </PicContainer>
        <ProjectDetails>
          <h2>{projectData.name}.</h2>
          <Divider
            color={parseInt(projectData.id) % 2 === 0 ? 'yellow' : 'blue'}
          />
          <p>{projectData.description}.</p>
          <LinkContainer>
            {projectData.link !== null && (
              <a
                style={{ color: 'inherit' }}
                target="_blank"
                href={projectData.link}
                rel="noopener noreferrer"
              >
                <SendIconStyled />
              </a>
            )}
            {projectData.github !== null && (
              <a
                style={{ color: 'inherit' }}
                target="_blank"
                href={projectData.github}
                rel="noopener noreferrer"
              >
                <GithubIconStyled />
              </a>
            )}
          </LinkContainer>
        </ProjectDetails>
      </ProjectInfo>
      <IdContainer>
        <h4>{projectData.id}</h4>
      </IdContainer>
    </Container>
  );
};

export default ProjectCard;
