import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import AnimatedPage from '../components/AnimatedPage';

import { ReactComponent as ReactIcon } from '../assets/svg/react.svg';
import { ReactComponent as Vue } from '../assets/svg/vue.svg';
import { ReactComponent as Javascript } from '../assets/svg/javascript.svg';
import { ReactComponent as MongoDB } from '../assets/svg/mongodb.svg';
import { ReactComponent as NodeJS } from '../assets/svg/nodejs.svg';
import { ReactComponent as Python } from '../assets/svg/python.svg';
import { ReactComponent as CPlusPlus } from '../assets/svg/c-plusplus.svg';
import { ReactComponent as Nest } from '../assets/svg/nest.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 35.1rem;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 1475px) {
    height: auto;
  }
`;

const ReactIconStyled = styled(ReactIcon)`
  width: 75px;
  height: 75px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
`;

const VueStyled = styled(Vue)`
  width: 75px;
  height: 75px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
`;

const JavascriptStyled = styled(Javascript)`
  width: 75px;
  height: 75px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
`;

const MongoDBStyled = styled(MongoDB)`
  width: 75px;
  height: 75px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
`;

const NodeJSStyled = styled(NodeJS)`
  width: 75px;
  height: 75px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
`;

const NestStyled = styled(Nest)`
  width: 75px;
  height: 75px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
`;

const PythonStyled = styled(Python)`
  width: 75px;
  height: 75px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
`;

const CPlusPlusStyled = styled(CPlusPlus)`
  width: 75px;
  height: 75px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s ease-in-out;

  &:hover {
    filter: none;
    -webkit-filter: none;
  }
`;

const SkillContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: ${(props) => props.theme.text};
    font-size: 45px;
    margin-bottom: 2rem;
  }

  .blue {
    color: ${(props) => props.theme.blue};
  }

  .yellow {
    color: ${(props) => props.theme.yellow};
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.1rem solid ${(props) => props.theme.secondary};
  border-radius: 25px;
  width: 85%;
  padding: 2rem;
  padding-bottom: 3rem;

  @media screen and (max-width: 1475px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: calc(1rem + 2vw);
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 650px) {
    border: none;
  }
`;

const BoxSS = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 85%;
`;

const SubBox = styled(motion.span)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    position: absolute;
    top: 60px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    color: ${(props) => props.theme.text};
    font-size: 20px;
  }

  &:hover {
    p {
      opacity: 1;
      top: 80px;
    }
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 2rem;
  }
`;

const SoftSkillSubBox = styled(motion.span)`
  width: ${(props) => props.length * 16}px;
  background-color: ${(props) =>
    props.bgcolor === 'blue' ? props.theme.blue : props.theme.yellow};
  border-radius: 25px;
  margin: 0.5rem;
  transition: 0.2s ease-in-out;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;

  p {
    color: ${(props) => props.theme.secondary};
    font-size: 20px;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1rem;
    transform: translateX(0%);
  }

  &:hover p {
    animation: move-rtl 1.3s linear infinite;
  }

  @keyframes move-rtl {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  &:hover {
    background-color: transparent;
    border: 0.1rem solid
      ${(props) =>
        props.bgcolor === 'blue' ? props.theme.blue : props.theme.yellow};
  }
`;

const Skills = () => {
  return (
    <AnimatedPage>
      <Container>
        <SkillContainer>
          <h1>
            <span className="blue">💻 Tech </span> I Use.
          </h1>
          <Box>
            <SubBox whileHover={{ scale: 1.1 }}>
              <ReactIconStyled />
              <p>React</p>
            </SubBox>
            <SubBox whileHover={{ scale: 1.1 }}>
              <VueStyled />
              <p>VueJS</p>
            </SubBox>
            <SubBox whileHover={{ scale: 1.1 }}>
              <JavascriptStyled />
              <p>Javascript</p>
            </SubBox>
            <SubBox whileHover={{ scale: 1.1 }}>
              <MongoDBStyled />
              <p>MongoDB</p>
            </SubBox>
            <SubBox whileHover={{ scale: 1.1 }}>
              <NodeJSStyled />
              <p>NodeJS</p>
            </SubBox>
            <SubBox whileHover={{ scale: 1.1 }}>
              <NestStyled />
              <p>Nest</p>
            </SubBox>
            <SubBox whileHover={{ scale: 1.1 }}>
              <PythonStyled />
              <p>Python</p>
            </SubBox>
            <SubBox whileHover={{ scale: 1.1 }}>
              <CPlusPlusStyled />
              <p>C++</p>
            </SubBox>
          </Box>
        </SkillContainer>
        <SkillContainer>
          <h1>
            <span className="yellow">🏋🏽‍♂️ Soft</span> Skills.
          </h1>
          <BoxSS>
            <SoftSkillSubBox
              length={11}
              bgcolor="blue"
              whileHover={{ scale: 1.1 }}
            >
              <p>Cooperative</p>
              <p>Cooperative</p>
            </SoftSkillSubBox>
            <SoftSkillSubBox
              length={9}
              bgcolor="yellow"
              whileHover={{ scale: 1.1 }}
            >
              <p>Empathic</p>
              <p>Empathic</p>
            </SoftSkillSubBox>
            <SoftSkillSubBox
              length={9}
              bgcolor="blue"
              whileHover={{ scale: 1.1 }}
            >
              <p>Rigorous</p>
              <p>Rigorous</p>
            </SoftSkillSubBox>
            <SoftSkillSubBox
              length={8}
              bgcolor="yellow"
              whileHover={{ scale: 1.1 }}
            >
              <p>Creative</p>
              <p>Creative</p>
            </SoftSkillSubBox>
            <SoftSkillSubBox
              length={11}
              bgcolor="blue"
              whileHover={{ scale: 1.1 }}
            >
              <p>Perseverant</p>
              <p>Perseverant</p>
            </SoftSkillSubBox>
            <SoftSkillSubBox
              length={8}
              bgcolor="yellow"
              whileHover={{ scale: 1.1 }}
            >
              <p>Friendly</p>
              <p>Friendly</p>
            </SoftSkillSubBox>
            <SoftSkillSubBox
              length={12}
              bgcolor="blue"
              whileHover={{ scale: 1.1 }}
            >
              <p>Autonomous</p>
              <p>Autonomous</p>
            </SoftSkillSubBox>
            <SoftSkillSubBox
              length={10}
              bgcolor="yellow"
              whileHover={{ scale: 1.1 }}
            >
              <p>Leadership</p>
              <p>Leadership</p>
            </SoftSkillSubBox>
          </BoxSS>
        </SkillContainer>
      </Container>
    </AnimatedPage>
  );
};

export default Skills;
