export const lightTheme = {
  body: '#ffffff',
  text: '#343434',
  background: '#efefef',
  secondary: '#363636',
  blue: '#5BA8FF',
  yellow: '#EEB800',
  bold: '#00000',
  fontFamily: "'Josefin Sans', sans-serif",
};

export const darkTheme = {
  body: '#1F1F1F',
  text: '#D2D2D2',
  background: '#0E0E0E',
  secondary: '#F3F3F3',
  blue: '#5BA8FF',
  yellow: '#FFBE03',
  bold: '#ffffff',
  fontFamily: "'Josefin Sans', sans-serif",
};
