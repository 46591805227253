import Logo from '../assets/portfolio_pic.png';
import NFT from '../assets/nft_marketplace_pic.png';
import EnglishTrainer from '../assets/english_trainer_pic.png';
import Roulette from '../assets/roulette_pic.png';
import TwitterScrapper from '../assets/twitter_scrapping_pic.png';

export const ProjectsData = [
  {
    id: 1,
    pic: Logo,
    name: 'Portfolio',
    description: 'My portfolio entirely design and developped by me',
    link: 'https://shironbeskiwin.fr/',
    github: 'https://github.com/shiron263/portfolio',
    tags: ['ReactJS', 'Framer Motion', 'Styled Components', 'Figma'],
  },
  {
    id: 2,
    pic: Roulette,
    name: 'Roulette',
    description:
      'Website where people can play roll game in different rooms. They can bet and chat with each others',
    link: null,
    github: null,
    tags: [
      'ReactJS',
      'Redux',
      'Socket.Io',
      'I18next',
      'MUI',
      'Express',
      'MongoDB',
      'Figma',
    ],
  },
  {
    id: 3,
    pic: TwitterScrapper,
    name: 'Twitter Scrapping Scripts',
    description: 'Scripts to scrap Twitter profiles',
    link: null,
    github: 'https://github.com/shiron263/twitter_scraping_scripts',
    tags: ['Python', 'Tweepy', 'Pandas', 'Data mining'],
  },
  {
    id: 4,
    pic: NFT,
    name: 'NFT Marketplace',
    description: 'Little mobile app to learn React Native',
    link: null,
    github: 'https://github.com/shiron263/nft_marketplace_reactn',
    tags: ['React Native'],
  },
  {
    id: 5,
    pic: EnglishTrainer,
    name: 'English Trainer',
    description:
      'Website to train english vocabulary with a personal dictionnary',
    link: null,
    github: null,
    tags: ['ReactJS', 'MUI', 'Excel'],
  },
];
