import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
`;

const TabItem = styled(Link)`
  color: ${(props) =>
    props.tabcolor === 'selected' ? props.theme.yellow : props.theme.text};
  text-decoration: none;

  h2 {
    position: relative;
  }

  h2:hover {
    color: ${(props) => props.theme.secondary};
  }

  h2:after {
    content: '';
    position: absolute;
    background-color: ${(props) => props.theme.yellow};
    height: 2px;
    width: 0;
    left: 0;
    bottom: -5px;
    transition: 0.4s;
  }

  h2:hover:after {
    width: 100%;
  }

  @media screen and (max-width: 750px) {
    h2 {
      font-size: 15px;
    }
  }
`;

const animationUp = {
  initial: { opacity: 0, y: 50 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      delay: 0.7,
    },
  },
};

const Tab = ({ location }) => {
  return (
    <Container>
      <TabItem to="/" tabcolor={location === '/' ? 'selected' : 'not'}>
        <motion.h2
          variants={animationUp}
          initial="initial"
          animate="animate"
          whileHover={{ scale: 1.1 }}
        >
          About Me
        </motion.h2>
      </TabItem>
      <TabItem
        to="/skills"
        tabcolor={location === '/skills' ? 'selected' : 'not'}
      >
        <motion.h2
          variants={animationUp}
          initial="initial"
          animate="animate"
          whileHover={{ scale: 1.1 }}
        >
          Skills
        </motion.h2>
      </TabItem>
      <TabItem
        to="/experiences"
        tabcolor={location === '/experiences' ? 'selected' : 'not'}
      >
        <motion.h2
          variants={animationUp}
          initial="initial"
          animate="animate"
          whileHover={{ scale: 1.1 }}
        >
          Experiences
        </motion.h2>
      </TabItem>
      <TabItem
        to="/projects"
        tabcolor={location === '/projects' ? 'selected' : 'not'}
      >
        <motion.h2
          variants={animationUp}
          initial="initial"
          animate="animate"
          whileHover={{ scale: 1.1 }}
        >
          Projects
        </motion.h2>
      </TabItem>
    </Container>
  );
};

export default Tab;
