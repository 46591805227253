import styled from 'styled-components';
import { darkTheme, lightTheme } from '../utils/Theme';
import { motion } from 'framer-motion';

import SBLogoDark from '../assets/SB_Logo.png';
import SBLogoLight from '../assets/SB_Logo_light.png';
import { ReactComponent as SunIcon } from '../assets/svg/sun-solid.svg';
import { ReactComponent as MoonIcon } from '../assets/svg/moon-solid.svg';
import { ReactComponent as LinkedinIcon } from '../assets/svg/linkedin.svg';
import { ReactComponent as GithubIcon } from '../assets/svg/github.svg';
import { Link } from 'react-router-dom';

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 8rem;
  }
  @media screen and (max-width: 650px) {
    .logo {
      width: 5rem;
    }
  }
`;

const ThemeController = styled(motion.div)`
  cursor: pointer;
`;

const SunIconStyled = styled(SunIcon)`
  height: 35px;
  width: 35px;
  transition: fill 0.2s ease-in-out;
  fill: ${(props) => props.color};
  &:hover {
    fill: ${(props) => props.hovercolor};
  }
`;

const MoonIconStyled = styled(MoonIcon)`
  height: 35px;
  width: 35px;
  transition: fill 0.2s ease-in-out;
  fill: ${(props) => props.color};
  &:hover {
    fill: ${(props) => props.hovercolor};
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

const NavBar = ({ theme, handleTheme }) => {
  return (
    <NavBarContainer>
      {theme === 'dark' ? (
        <Link to="/">
          <img className="logo" src={SBLogoDark} alt="logo_sb" />
        </Link>
      ) : (
        <Link to="/">
          <img className="logo" src={SBLogoLight} alt="logo_sb" />
        </Link>
      )}
      <ThemeController
        whileHover={{ scale: 1.3 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleTheme}
      >
        {theme === 'dark' ? (
          <SunIconStyled
            color={darkTheme.secondary}
            hovercolor={darkTheme.yellow}
          />
        ) : (
          <MoonIconStyled
            color={lightTheme.secondary}
            hovercolor={lightTheme.blue}
          />
        )}
      </ThemeController>
      <SocialIcons>
        <motion.span whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <a
            style={{ color: 'inherit' }}
            target="_blank"
            href="https://www.linkedin.com/in/shiron-beskiwin/"
            rel="noopener noreferrer"
          >
            <LinkedinIcon
              style={{ cursor: 'pointer' }}
              width={35}
              height={35}
              fill={
                theme === 'dark' ? darkTheme.secondary : lightTheme.secondary
              }
            />
          </a>
        </motion.span>
        <motion.span whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <a
            style={{ color: 'inherit' }}
            target="_blank"
            href="https://github.com/shiron263"
            rel="noopener noreferrer"
          >
            <GithubIcon
              style={{ cursor: 'pointer' }}
              width={35}
              height={35}
              fill={
                theme === 'dark' ? darkTheme.secondary : lightTheme.secondary
              }
            />
          </a>
        </motion.span>
      </SocialIcons>
    </NavBarContainer>
  );
};

export default NavBar;
