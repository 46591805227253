import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Container = styled(motion.div)`
  position: relative;
  width: 25rem;
  height: 11rem;
  z-index: 1;
  border-radius: 30px;
  background: ${(props) =>
    props.selectedtheme === 'dark' ? 'rgb(50, 50, 50)' : 'rgb(240,240,240)'};
  background: ${(props) =>
    props.selectedtheme === 'dark'
      ? 'linear-gradient(180deg, rgba(50, 50, 50, 0.15) 0%, rgba(40, 40, 40, 1) 100%)'
      : 'linear-gradient(180deg, rgba(240,240,240,0.15) 0%, rgba(242,242,242,1) 100%)'};

  display: flex;
  align-items: center;
  justify-content: space-around;

  h2 {
    color: ${(props) => props.theme.bold};
    font-size: 30px;
  }
  h3 {
    color: ${(props) => props.theme.text};
  }
  p {
    color: ${(props) => props.theme.text};
  }

  @media screen and (max-width: 430px) {
    width: 18rem;
  }
`;

const DateContainer = styled.div`
  position: absolute;
  top: -10px;
  left: 160px;
  z-index: -1;

  h4 {
    color: ${(props) => props.theme.text};
    color: transparent;
    -webkit-text-stroke: 0.03rem ${(props) => props.theme.text};
    font-size: 25px;
  }
`;

const TechContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: 90%;
  span {
    transform-origin: 0 0;
    writing-mode: vertical-rl;
    color: ${(props) => props.theme.text};
    font-size: 10.5px;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 360px) {
    display: none;
  }
`;

const WorkDetail = styled.div`
  height: 65%;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.span`
  border: 1px solid ${(props) => props.theme.blue};
  width: 40%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ExperienceCard = ({ workData, theme }) => {
  return (
    <Container
      whileHover={{ scale: 1.2 }}
      transition={{ duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] }}
      selectedtheme={theme}
    >
      <TechContainer>
        {workData.tags.map((tag, index) => {
          return <span key={index}>{tag}</span>;
        })}
      </TechContainer>
      <WorkDetail>
        <h2>{workData.name}</h2>
        <h3>{workData.poste}</h3>
        <Divider />
        <p>📑 {workData.contrat}</p>
        <p>📌 {workData.location}</p>
      </WorkDetail>
      <DateContainer>
        <h4>{workData.date}</h4>
      </DateContainer>
    </Container>
  );
};

export default ExperienceCard;
