import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ProjectCard from '../components/ProjectCard';
import AnimatedPage from '../components/AnimatedPage';

import { ProjectsData } from '../data/ProjectsData';
import { motion } from 'framer-motion';

const Caroussel = styled(motion.div)`
  height: 35.1rem;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  @media screen and (max-width: 650px) {
    min-height: 100vh;
    height: auto;
  }

  @media screen and (max-width: 1475px) {
  }
`;

const InnerCaroussel = styled(motion.div)`
  width: auto;
  margin: 0 2rem;

  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6rem;
  margin-bottom: 3rem;

  cursor: grab;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;

  h1 {
    color: ${(props) => props.theme.text};
    font-size: 45px;
  }

  @media screen and (max-width: 340px) {
    h1 {
      font-size: 30px;
    }
  }
`;

const Projects = ({ theme }) => {
  const [width, setWidth] = useState(0);
  const carousselRef = useRef();

  useEffect(() => {
    setWidth(
      carousselRef.current.scrollWidth - carousselRef.current.offsetWidth
    );
  }, []);

  return (
    <AnimatedPage>
      <Caroussel ref={carousselRef}>
        <TitleContainer>
          <h1>👨🏽‍💻 My Projects.</h1>
        </TitleContainer>
        <InnerCaroussel
          whileTap={{ cursor: 'grabbing' }}
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
        >
          {ProjectsData.map((project) => {
            return (
              <ProjectCard
                key={project.id}
                theme={theme}
                projectData={project}
              />
            );
          })}
        </InnerCaroussel>
      </Caroussel>
    </AnimatedPage>
  );
};

export default Projects;
