import React from 'react';
import styled from 'styled-components';
import AnimatedPage from '../components/AnimatedPage';
import ExperienceCard from '../components/ExperienceCard';

import { Work } from '../data/ExperiencesData';

const Container = styled.div`
  height: 35.1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  h1 {
    color: ${(props) => props.theme.text};
    font-size: 45px;
    margin-bottom: 2rem;
  }

  p {
    color: ${(props) => props.theme.text};
    font-size: 14px;
  }

  @media screen and (max-width: 1350px) {
    min-height: 100vh;
    height: auto;

    h1 {
      font-size: 35px;
    }
  }

  @media screen and (max-width: 480px) {
    h1 {
      font-size: 21px;
    }
  }
`;

const WorkContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: calc(1rem + 2vw);

  @media screen and (max-width: 1350px) {
    display: flex;
    flex-direction: column;
  }
`;

const Divider = styled.span`
  border: 1px solid ${(props) => props.theme.yellow};
  width: 40%;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const Experiences = ({ theme }) => {
  return (
    <AnimatedPage>
      <Container>
        <h1>💼 Professional Experiences.</h1>
        <WorkContainer>
          {Work.slice(0)
            .reverse()
            .map((work) => {
              return (
                <ExperienceCard theme={theme} key={work.id} workData={work} />
              );
            })}
        </WorkContainer>
        <Divider />
        <p>Details in my CV.</p>
      </Container>
    </AnimatedPage>
  );
};

export default Experiences;
